import gritExtraLogo from './../images/gritextra-logo.png'

const header = {
    data: new Map([
        ['menuLinks', 
            [
                {
                    type:`internal-link`,
                    href: `/schedule`,
                    label: `Schedule`,
                },
                {
                    type:`internal-link`,
                    href: `/movies`,
                    label: `Movies`,
                },
                {
                    type:`internal-link`,
                    href: `/find-us`,
                    label: `Find Us`,
                },
            ]
        ],
        ['logoItems', 
            [
                {
                    image: {
                        url: gritExtraLogo,
                        width: 730,
                        height: 350,
                    },
                    href: `/gritxtra`,
                    height: 3.5,
                    baselineAdjustment: 0.55,
                    text: 'Grit XTRA',
                },
            ]
        ]
    ])
}

const footer = {
    data: new Map([
        ['navSections', 
            [
                {
                    "title": "Site Navigation",
                    "links": [
                        {
                            "href": "/schedule",
                            "type": "internal-link",
                            "label": "Schedule"
                        },
                        {
                            "href": "/movies",
                            "type": "internal-link",
                            "label": "Movies"
                        },
                        {
                            "href": "/find-us",
                            "type": "internal-link",
                            "label": "Find Us"
                        }
                    ],
                    "classname": "footer-nav"
                },
                {
                    "title": "Networks",
                    "links": [
                        {
                            "href": "https://grittv.com",
                            "type": "internal-link",
                            "label": "Grit"
                        },
                        {
                            "href": "https://grittv.com/gritxtra",
                            "type": "internal-link",
                            "label": "Grit Xrta"
                        }
                    ],
                    "classname": "footer-nav"
                },
                {
                    "title": "Privacy & Terms",
                    "links": [
                        {
                            "href": "/privacy-policy",
                            "type": "internal-link",
                            "label": "Privacy Policy"
                        },
                        {
                            "href": "/terms-of-use",
                            "type": "internal-link",
                            "label": "Terms of Use"
                        },                        
                        {
                            "href": "/privacy-center",
                            "type": "internal-link",
                            "label": "Privacy Center"
                        }
                    ],
                    "classname": "footer-nav"
                },
                {
                    "title": "Information",
                    "links": [
                        {
                            "href": "https://support.grittv.com/support/solutions",
                            "type": "external-link",
                            "label": "Help/FAQs"
                        },
                        {
                            "href": "https://support.grittv.com/support/home",
                            "type": "external-link",
                            "label": "Contact Us"
                        },
                    ],
                    "classname": "footer-nav"
                }
            ]
        ],
        ['socialLinks',
            [
                {
                    platform: 'facebook',
                    url: 'https://www.facebook.com/grittvnetwork?ref=hl',
                },
                {
                    platform: 'twitter',
                    url: 'https://twitter.com/grit_tv',
                },
                {
                    platform: 'instagram',
                    url: 'http://instagram.com/grittv',
                },
                {
                    platform: 'ticktok',
                    url: 'https://www.tiktok.com/@grittv?lang=en',
                    image: {
                        url: 'https://ewscripps.brightspotcdn.com/7d/41/859885404930849a4d9cfcbe3789/ion-social-tiktok-1.svg',
                        width: 129,
                        height: 150,
                    },
                },
            ]
        ]
    ])  
}

const resources = new Map([
    ['header', header],
    ['footer', footer],
])

export {
    resources,
}